import * as React from "react"

import PickerScreen from '../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="What's your goal?"
    options={[
      {
        label: "Rapid prototyping",
        link: "/app/digital-product-design/lo-fi/rapid-prototyping/"
      },
      {
        label: "Idea testing",
        link: "/app/digital-product-design/lo-fi/idea-testing/"
      },
      {
        label: "User flow mapping",
        link: "/app/tool/miro-or-mural/"
      }
  ]}/>
)

export default Picker;


